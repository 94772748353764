import { useEffect, useState } from 'react';
import { usePickUpAndDelivery } from '../../talons/usePickUpAndDelivery';
import PickupLocations from '../../queries/pickupLocations.graphql';
import { useQuery } from '@apollo/client';
import { connect, useSelector } from 'react-redux';
import SelectInput from '../SelectInput';
import { Form, Option } from 'informed';

interface PickupAndDeliveryButtons {
    setSelectedStore?: Function;
    productsInCart: any;
}

const PickupAndDeliveryButtons = (props: PickupAndDeliveryButtons) => {
    const { data, loading, error } = useQuery(PickupLocations);
    const handledStores = data?.pickupLocations?.items
        ? [...data?.pickupLocations?.items]
        : [];

    const filteredStores = handledStores.filter((store) => {
        const name = store.name.toLowerCase();
        if (name.includes('megastore')) {
            return store;
        }
    });

    const { setSelectedStore, productsInCart } = props;

    const pickupAndDelivery = useSelector(
        (state: any) => state.pickupAndDelivery
    );

    const storePickupState = pickupAndDelivery.cartPickupDeliveryState;
    const pickupInfo = pickupAndDelivery.pickupInfo;
    let initialPickupInfo = '';
    filteredStores.forEach((store: any) => {
        if (store.pickup_location_code == pickupInfo) {
            initialPickupInfo = store.name;
        }
    });

    const isOnlineOnly =
        productsInCart.filter(
            (f: { product: { product_state: string } }) =>
                f.product?.product_state?.trim() == 'I'
        ).length > 0
            ? true
            : false;

    const { setCartPickupAndDeliveryState, addPickUpInfo } =
        usePickUpAndDelivery();

    useEffect(() => {
        isOnlineOnly ? setCartPickupAndDeliveryState('delivery') : null;
    }, [isOnlineOnly]); //eslint-disable-line

    const [deliveryState, setDeliveryState] = useState(
        storePickupState == 'delivery' || isOnlineOnly ? true : false
    );
    const [pickupState, setPickupState] = useState(
        storePickupState == 'pickup' && !isOnlineOnly ? true : false
    );

    // const locations: any = [];
    // data && data.storeLocations && data.storeLocations.allStores
    //     ? data.storeLocations.allStores
    //           .filter((f: { megastore_filial: string }) => f.megastore_filial)
    //           .map((store: any) => {
    //               store.mhs_store_name &&
    //               store.mhs_store_ids &&
    //               store.mhs_warehouse_ids &&
    //               store.mhs_display_ids &&
    //               store.mhs_store_zone
    //                   ? locations.push({
    //                         name: store.mhs_store_name,
    //                         storeIds: store.mhs_store_ids
    //                             ? store.mhs_store_ids.split(',')
    //                             : null,
    //                         warehouseIds: store.mhs_warehouse_ids
    //                             ? store.mhs_warehouse_ids.split(',')
    //                             : null,
    //                         displayId: store.mhs_display_ids
    //                             ? store.mhs_display_ids.split(',')
    //                             : null,
    //                         zone: store.mhs_store_zone,
    //                         email: store.email,
    //                         city: store.city,
    //                         phone: store.phone,
    //                         zip_code: store.zip_code,
    //                         address: store.address,
    //                         id: store.id
    //                     })
    //                   : null;
    //           })
    //     : null;

    if (error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error(error);
        }
        return <span />;
    }
    if (loading) {
        // return loadingIndicator;
        return <span />;
    }

    return (
        <div className={'m-1 flex flex-col md:flex-row w-full'}>
            <div
                className={
                    'flex justify-start items-center relative bg-gray-100 margin-1 p-2 rounded-lg'
                }
                onClick={() => {
                    setDeliveryState(true);
                    setPickupState(false);
                    setCartPickupAndDeliveryState('delivery');
                }}
            >
                <div className={'relative pl-6 cursor-pointer'}>
                    <button
                        type="button"
                        onClick={() => {
                            setDeliveryState(true);
                            setPickupState(false);
                            setCartPickupAndDeliveryState('delivery');
                        }}
                        className={
                            deliveryState
                                ? 'block w-5 h-5 mr-2 bg-white border border-solid border-skin-primary cursor-pointer rounded-full absolute -top-[2px] left-0 deliveryChecked'
                                : 'block w-5 h-5 mr-2 bg-white border border-solid border-skin-primary cursor-pointer rounded-full absolute -top-[2px] left-0'
                        }
                    />
                    <div>
                        <span
                            className={
                                'text-base font-semibold block ml-1 leading-4'
                            }
                        >
                            Lieferung nach Hause
                        </span>
                    </div>
                </div>
            </div>
            <div
                className={
                    'flex justify-start items-center relative bg-gray-100 margin-1 p-2 md:pl-2 rounded-lg sm:ml-2 mt-2 sm:mt-0'
                }
            >
                <div
                    className={'relative pl-6 cursor-pointer sm:w-auto'}
                    onClick={() => {
                        setPickupState(true);
                        setDeliveryState(false);
                        setCartPickupAndDeliveryState('pickup');
                    }}
                >
                    <button
                        type="button"
                        className={
                            pickupState
                                ? 'block w-5 h-5 mr-2 bg-white border border-solid border-skin-primary cursor-pointer rounded-full absolute -top-[2px] left-0 deliveryChecked'
                                : 'block w-5 h-5 mr-2 bg-white border border-solid border-skin-primary cursor-pointer rounded-full absolute -top-[2px] left-0'
                        }
                        disabled={isOnlineOnly}
                    />
                    <div>
                        <span
                            className={
                                'text-base font-semibold block ml-1 leading-4 w-max'
                            }
                        >
                            Abholung - Kostenlos
                        </span>
                    </div>
                </div>
                <div
                    className={
                        'flex justify-start items-center relative bg-gray-100 margin-1 sm:w-[210px] ml-4'
                    }
                >
                    <span className={'text-sm block '}>
                        <div className={'flex items-center filialSelectCart'}>
                            <label htmlFor={'selectedPickupStore'}>
                                Filiale:
                            </label>
                            {filteredStores.length > 0 ? (
                                <Form
                                    onSubmit={(v: {
                                        selectedStorePacketCart: string;
                                    }) => {
                                        v.selectedStorePacketCart != ''
                                            ? addPickUpInfo(
                                                  v.selectedStorePacketCart
                                              )
                                            : null;
                                        v.selectedStorePacketCart != ''
                                            ? setSelectedStore &&
                                              setSelectedStore(
                                                  v.selectedStorePacketCart
                                              )
                                            : null;
                                    }}
                                >
                                    <div className="flex items-start justify-between text-sm">
                                        <SelectInput
                                            customLabel=""
                                            id={'selectedStorePacketCart'}
                                            field="selectedStorePacketCart"
                                            initialValue={initialPickupInfo}
                                            additionalclasses="pr-6 w-[95%] border appearance-none mr-2 min-h-[24px] text-sm rounded-lg mx-2 py-0"
                                            className="mb-0 "
                                            submitonchange={true}
                                            disabled={
                                                deliveryState || isOnlineOnly
                                                    ? true
                                                    : false
                                            }
                                            noIcon={false}
                                        >
                                            <Option key={'filiale'} value={''}>
                                                Filiale
                                            </Option>
                                            {filteredStores
                                                .sort(
                                                    (
                                                        a: { name: string },
                                                        b: { name: string }
                                                    ) =>
                                                        a.name > b.name ? 1 : -1
                                                )
                                                .map(
                                                    (store: {
                                                        pickup_location_code: string;
                                                        name: string;
                                                        id: string;
                                                    }) => (
                                                        <Option
                                                            key={store.id}
                                                            value={
                                                                store.pickup_location_code
                                                            }
                                                        >
                                                            {store.name}
                                                        </Option>
                                                    )
                                                )}
                                        </SelectInput>
                                    </div>
                                </Form>
                            ) : null}
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ pickupAndDelivery }: any) => {
    return {
        storePickupState: pickupAndDelivery.cartPickupDeliveryState,
        pickupInfo: pickupAndDelivery.pickupInfo
    };
};

export default connect(mapStateToProps)(PickupAndDeliveryButtons);
